import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Traec from "traec";
import Crypto from "crypto";

import CreatableSelect from "react-select/creatable";
import Select from "react-select";

// Special fields that generate the list of options from the API
const API_FIELDS = {
  "Select your clients from list below": { isClient: true },
  "Please select any contractors you work with from the list below": { isContractor: true },
  "Please select the organisations you are a member of": { isOrganisation: true }
};

export const isApiField = header => header in API_FIELDS;

function FieldMultiSelect({ field, value, disabled, onChangeHandler }) {
  let [state, setState] = useState({});
  let [options, setOptions] = useState([]);

  let header = field.get("header");
  let hash = Crypto.createHash("sha1")
    .update(header)
    .digest("hex")
    .substring(0, 8);

  useEffect(() => {
    Traec.fetchRequired.bind({
      props: {},
      state,
      setState,
      requiredFetches: [
        new Traec.Fetch(
          "company_dispatch",
          "post",
          {},
          {
            preDispatchHook: action => {
              action.fetchParams.body = {
                type: "GET_MATCHING_META",
                payload: API_FIELDS[header]
              };
              action.fetchParams.url = `${action.fetchParams.url}?hash=${hash}`;
              action.stateParams.stateSetFunc = (state, action) => {
                console.log("Got response data from GET_MATCHING_META", hash, header, action);
                let items = action.payload?.payload || [];
                setOptions(
                  items.sort((a, b) => a.name.localeCompare(b.name)).map(i => ({ value: i.uid, label: i.name }))
                );
                return state;
              };
              console.log("Dispatching action for GET_MATCHING_META", hash, header, action);
              return action;
            }
          }
        )
      ]
    })();
  });

  if (options.length == 0) {
    return null;
  }

  //let options = (field.get("options") || Traec.Im.List())
  //  .toJS()
  //  .map(i => (typeof i === "string" ? { value: i, label: i } : i));
  let valueSet = new Traec.Im.Set(value || []);
  let defaultValue = (options || []).filter(i => valueSet.has(i.value));
  console.log(
    "RENDERING GET_MATCHING_META FieldMultiSelectConnected",
    hash,
    header,
    valueSet.toJS(),
    defaultValue,
    options
  );

  return (
    <Select
      isClearable={true}
      isMulti={true}
      placeholder="Start typing to search..."
      onChange={data => {
        let value = data ? data.map(i => i.value) : null;
        console.log("Handling change to value", value, data);
        onChangeHandler({ target: { value } });
      }}
      options={options}
      defaultValue={defaultValue}
      isDisabled={disabled}
    />
  );
}

//const mapStateToProps = (state, ownProps) => { return state };
//export default connect(mapStateToProps)(FieldMultiSelect);
export default FieldMultiSelect;
