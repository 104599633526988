import React, { useState } from "react";
import Traec from "traec";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { ProjectPermission } from "traec/utils/permissions/project";
import { BSBtnDropdown, BSModal } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";

import { getProjectProps } from "AppSrc/project/utils";
import { MiniErrorBoundary } from "AppSrc/project/report/error";
import { workPackageFields } from "AppSrc/project/wptree/form";
import ProjectWPTree from "./wpTree";
import { Indent } from "./indent";
import { confirmDelete } from "traec-react/utils/sweetalert";

const deleteProject = (e, projectId, project) => {
  e.preventDefault();

  let redirectUrl = "/accounts/profile";
  if (project) {
    let companyId = (project.getInPath("company.uid") || "").substring(0, 8);
    redirectUrl = companyId ? `/company/${companyId}` : redirectUrl;
  }
  console.log("WILL REDIRECT TO", redirectUrl);

  confirmDelete({
    text: `This will delete Project (${projectId.substring(
      0,
      8
    )}).  ALL MEMBERSHIP DATA AND HISTORICAL REPORTED DATA WILL BE PERMANENTLY LOST. Are you sure you would like to proceed?`,
    onConfirm: () => {
      confirmDelete({
        text: `Are you really really sure?  There no recovering this data.`,
        onConfirm: () => {
          confirmDelete({
            text: `...Really?`,
            onConfirm: () => {
              let fetch = new Traec.Fetch("project", "delete", { projectId });
              fetch.updateFetchParams({
                postSuccessHook: () => {
                  location.href = redirectUrl;
                }
              });
              fetch.dispatch();
            }
          });
        }
      });
    }
  });
};

function ProjectAdminDropdown({ projectId, project, trackerId, refId, commitId, rootCommitId, treeId }) {
  if (!project || !projectId) {
    return null;
  }

  let modalId = `${projectId}-wp-modal`;
  let _projectId = projectId.substring(0, 8);

  // Get the fetch for adding a work package
  let fromCommitId = commitId || rootCommitId;

  let fetch = new Traec.Fetch("tracker_ref_tree_branch", "post", {
    trackerId,
    refId,
    commitId,
    treeId,
    skip_categories: true
  });
  fetch.updateFetchParams({
    preFetchHook: body => {
      let _body = {
        ...body,
        ref_name: body.name || "master",
        from_commit: fromCommitId
      };
      console.log("CREATING REF WITH PARAMETERS", _body);
      return _body;
    },
    postSuccessHook: () => {
      $(`#${modalId}`).modal("hide");
    }
  });

  return (
    <MiniErrorBoundary>
      <BSBtnDropdown
        header={" "}
        floatStyle={"sidebar-dropdown-text float-right"}
        links={[
          {
            name: "Add a Reporting Package",
            onClick: e => {
              $(`#${modalId}`).modal("show");
            }
          },
          { name: "Go to Settings", linkTo: `/project/${_projectId}/details` },
          {},
          { name: "Delete", onClick: e => deleteProject(e, projectId, project) }
        ]}
      />
      <div className="sidebar-dropdown-text">
        <BSModal
          id={modalId}
          title={"Add a Reporting Package"}
          body={
            <BaseFormConnected
              params={fetch.params}
              fields={workPackageFields}
              forceShowForm={true}
              hideUnderline={true}
            />
          }
        />
      </div>
    </MiniErrorBoundary>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;

  // Get the Traker and root ref/commit details
  let { trackerId, tracker } = getProjectProps(state, projectId);
  let rootRefId = tracker ? tracker.get("root_master") : null;
  let rootRef = rootRefId ? state.getInPath(`entities.refs.byId.${rootRefId}`) : null;
  let commitId = rootRef ? rootRef.getInPath("latest_commit.uid") : null;
  let treeId = rootRef ? rootRef.getInPath("latest_commit.tree_root.uid") : null;

  return { trackerId, commitId, refId: rootRefId, rootCommitId: commitId, treeId };
};

const ProjectAdminDropdownConnected = connect(mapStateToProps)(ProjectAdminDropdown);

export default class ProjectRow extends React.Component {
  constructor(props) {
    super(props);

    let { project } = props;
    let projectId = project ? project.get("uid") : null;

    this.state = {
      collapsed: projectId ? localStorage.getItem(`sidebar-${projectId}`, "false") == "true" : false
    };
  }

  toggleCollapsed(e, projectId) {
    e.preventDefault();
    let value = !this.state.collapsed;
    localStorage.setItem(`sidebar-${projectId}`, value);
    this.setState({ collapsed: value });
    console.log("Set collapsed state for project", projectId, value);
  }

  render() {
    let { project, currentIds, depth } = this.props;
    if (!project) {
      return null;
    }
    let projectId = project ? project.get("uid") : null;

    let currentId = currentIds.projectId;
    let isCurrentProject = currentId ? currentId == projectId : false;

    let currentRefOrProjectId = currentIds.refId || currentIds.projectId;
    let isCurrentRefOrProject = currentRefOrProjectId && currentRefOrProjectId == projectId;

    let bgColor = isCurrentProject && !currentIds.refId ? "bg-info" : "";

    //console.log(project.get("name"), depth, isCurrentProject);
    return (
      <React.Fragment>
        <div className={`row m-0 p-0 ${bgColor}`}>
          <Indent
            depth={depth}
            expanded={isCurrentProject}
            onClickHandler={e => {
              this.toggleCollapsed(e, projectId);
            }}
          />

          <p className={`m-0 p-0 mr-2 col`} style={{ display: "inline-block", verticalAlign: "middle" }}>
            <Link to={`/project/${projectId.substring(0, 8)}`}>{project.get("name")}</Link>
          </p>

          <MiniErrorBoundary>
            {isCurrentRefOrProject ? (
              <ProjectPermission projectId={projectId} requiresAdmin={true}>
                <ProjectAdminDropdownConnected projectId={projectId} {...this.props} />
              </ProjectPermission>
            ) : null}
          </MiniErrorBoundary>
        </div>

        {isCurrentProject ? <ProjectWPTree projectId={projectId} hideDelete={true} {...this.props} /> : null}
      </React.Fragment>
    );
  }
}
