import React from "react";

export function CompanyWarningMessage() {
  return (
    <div className="alert alert-warning text-center">
      <strong>
        The industry dashboard will go live in October after the Survey has closed and all data has been processed. At
        this point, the industry benchmarks and ONS targets will also appear on your company Dashboard.
      </strong>
    </div>
  );
}
