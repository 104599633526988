import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";
import { Tooltip } from "react-tippy";
import { BSCard, BSBtn } from "traec-react/utils/bootstrap";
import { DateString, ReportPeriodString } from "AppSrc/project/components";
import { getColor } from "AppSrc/dashboards/icons/utils";
import { charToColor } from "./utils";
import Octicon from "react-octicon";
import { dataToState, getFetchBody } from "../sustainabilityPanel/helpers";
import { Spinner } from "traec-react/utils/entities";
import { comapnyDispatchToDownload } from "AppSrc/company/home";

const { Im } = Traec;

const exportCSV = props => {
  let { companyId, indicatorId, indicator, cumulation, pending, setPending } = props;

  let name = indicator?.get("name") || indicatorId.substring(0, 8);

  comapnyDispatchToDownload({
    companyId,
    pending,
    setPending,
    actionName: "SUSTOOL_DASHBOARD_EXPORT",
    fileName: `${name}_by_project.csv`,
    payload: {
      part: "INDICATOR_RAG_BY_PROJECT_DATA",
      format: "csv",
      indicator_id: indicatorId,
      cumulation
    }
  });
};

const getResultPath = ({ cumulation, indicatorId, categoryName, projectId }) => {
  let _pathStart = indicatorId ? "INDICATOR_RAG_BY_PROJECT_DATA" : "ISSUE_RAG_BY_PROJECT_DATA";
  let _pathEnd = indicatorId ? `.${indicatorId}` : `.${categoryName}`;
  return `${_pathStart}.${cumulation || "total"}.projects.${projectId}${_pathEnd}`;
};

const ReportProjectCell = props => {
  let [redirectTo, setRedirectTo] = useState(null);

  // Do any pending redirection
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  // Render the cell
  let { projectId, categoryId, category: categoryName, indicatorId, reportPeriod, issue, cumulation } = props;

  let result = reportPeriod.getInPath(getResultPath({ ...props, categoryName }));

  let { color, content } = getReportingPeriodRAG({ result, categoryId, indicatorId, issueName: issue?.fullName });

  console.log("contentReportProjectCell HERE", typeof content);

  let styleObj = {
    backgroundColor: "none",
    cursor: "pointer",
    borderLeft: "0.5px solid grey",
    textAlign: "center"
  };

  if (typeof content === "string") {
    return (
      <div className="col-sm align-middle" style={styleObj} onClick={() => setRedirectTo(`/project/${projectId}/`)}>
        {indicatorId ? (
          content
        ) : (
          <div className="col-sm-12">
            <Spinner title="Building table" />
          </div>
        )}
      </div>
    );
  } else
    return (
      <div className="col-sm-12">
        <Spinner title="Building table" />
      </div>
    );
};

const getReportingPeriodRAG = ({ result }) => {
  return {
    color: getColor(charToColor(result?.get("rag"))),
    content: result?.get("value")?.toFixed(2) || ""
  };
};

const ReportProjectRow = props => {
  let { project, selectedReportingPeriods, index } = props;
  if (!project) {
    return (
      <div className="col-sm-12">
        <Spinner title="Building table" />
      </div>
    );
  }
  let projectId = project.get("uid");

  let cells = selectedReportingPeriods
    ?.toList()
    .map((reportPeriod, i) => (
      <ReportProjectCell key={i} {...props} projectId={projectId} reportPeriod={reportPeriod} />
    ));

  return (
    <div className="row" style={{ backgroundColor: index % 2 ? "#e6ecff" : "none" }}>
      <div className="col-sm-3">
        <Link to={`/project/${projectId.substring(0, 8)}/`}>{project.get("name")}</Link>
      </div>
      {cells}
    </div>
  );
};

const ReportHeaderRow = ({ reportingPeriods }) => {
  return (
    <div className="row" style={{ borderBottom: "0.5px solid grey" }}>
      <div className="col-sm-3">
        <b></b>
      </div>
      {reportingPeriods
        ? reportingPeriods.toList().map((rp, i) => (
            <div className="col-sm" key={i}>
              <b>
                <DateString obj={rp} field={"endDate"} add={-1} />
              </b>
            </div>
          ))
        : null}
    </div>
  );
};

const TitleTooltip = props => {
  let { text } = props;

  if (!text) {
    return null;
  }
  return (
    <Tooltip animateFill={false} html={<div className="text-left">{text}</div>}>
      <Octicon name="info" className="ml-2" />
    </Tooltip>
  );
};

const getTitleAndHelpText = ({ indicatorOnly, categoryName, indicator }) => {
  let title;
  let helpText;

  if (indicatorOnly) {
    title = `Performance breakdown: ${indicator?.get("name")}`;
    helpText = (
      <div className="text-left">
        <p>
          This table uses a Red, Amber and Green (RAG) system to indicate the performance of each reporting package
          evaluated on the selected indicator, based on the targets and thresholds at the reporting package level.
        </p>
        <p>A red cell indicates underperformance for the selected indicator.</p>
        <p>
          An amber cell indicates performance above the red/amber threshold but below the target for the selected
          indicator.
        </p>
        <p>A green cell indicates satisfactory performance for the selected indicator.</p>
        <p>A grey cell indicates no targets or thresholds were set or that no data has been reported.</p>
        <p>Indicator id: {indicator?.getInPath("_key")?.substring(0, 8)}</p>
      </div>
    );
  } else {
    title = `Performance breakdown: ${categoryName}`;
    helpText = (
      <div className="text-left">
        <p>
          This table uses a Red, Amber and Green (RAG) system to indicate the performance of each project evaluated on
          the selected indicator, based on the targets and thresholds at the project level.
        </p>
        <p>A red cell indicates underperformance for the selected indicator.</p>
        <p>
          An amber cell indicates performance above the red/amber threshold but below the target for the selected
          indicator.
        </p>
        <p>A green cell indicates satisfactory performance for the selected indicator.</p>
        <p>A grey cell indicates no targets or thresholds were set or that no data has been reported.</p>
      </div>
    );
  }
  return { title, helpText };
};

const getPreDispatchHook = props => {
  let { fetchBody } = props;
  return action => {
    action.fetchParams.body = fetchBody;
    action.fetchParams.headers = { "content-type": "application/json" };
    action.fetchParams.rawBody = false;
    action.fetchParams.throttleTimeCheck = 1000 * 3600; // Throttle request to every hour (to prevent calling backend every click)
    action.stateParams.stateSetFunc = (state, data) => dataToState(props, state, data);
    console.log("Calling dispatch for ISSUE/INDICATOR_RAG_BY_PROJECT data", action);
    return action;
  };
};

const CompanyReportProjectResults = props => {
  let {
    indicator,
    category: categoryName,
    category_id: categoryId,
    indicatorId,
    indicatorOnly,
    selectedReportingPeriods,
    projects,
    query_string
  } = props;

  let [pendingExport, setPendingExport] = useState(false);

  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      requiredFetches: [
        new Traec.Fetch("company_dispatch", "post", {}, { preDispatchHook: getPreDispatchHook(props) }),
        new Traec.Fetch("project", "list")
      ]
    });
    console.log("fetchComplete", props, "indicatorId =>", indicatorId, "query string", query_string);
  }, [indicatorId, query_string]);

  if ((indicatorOnly && !indicator) || !categoryId) {
    return (
      <div className="col-sm-12">
        <Spinner title="Building table" />
      </div>
    );
  }

  // Check if any of the projects have data in any reporting period
  let hasData = projects.some(project => {
    let projectId = project.get("uid");
    let anyReportPeriodHasData = selectedReportingPeriods
      ?.toList()
      .some(reportPeriod => reportPeriod.getInPath(getResultPath({ ...props, projectId, categoryName })));

    console.log("anyReportPeriodHasData", anyReportPeriodHasData);
    return anyReportPeriodHasData;
  });

  if (!hasData || !projects?.size) {
    return (
      <div className="col-sm-12">
        <Spinner title="Loading performance data for issues" />
      </div>
    );
  }

  let { title, helpText } = getTitleAndHelpText({ indicatorOnly, categoryName, indicator });

  const rows = projects.map((project, index) => {
    return <ReportProjectRow key={index} {...props} project={project} index={index} />;
  });

  return (
    <BSCard
      widthOffset="col-sm-12"
      title={
        <>
          <span>{title}</span>
          <TitleTooltip text={helpText} />
        </>
      }
      button={
        <BSBtn
          onClick={e => {
            e.preventDefault();
            exportCSV({
              ...props,
              pending: pendingExport,
              setPending: setPendingExport
            });
          }}
          disabled={pendingExport}
          text={
            pendingExport ? <div className="spinner-border spinner-border-sm" /> : <Octicon name="cloud-download" />
          }
        />
      }
      body={
        <>
          <ReportHeaderRow reportingPeriods={selectedReportingPeriods} />
          {rows}
        </>
      }
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  let { category_id, indicatorId, selectedReportingPeriods, cumulation } = ownProps;

  let _key = indicatorId ? "INDICATOR_RAG_BY_PROJECT_DATA" : "ISSUE_RAG_BY_PROJECT_DATA";

  let { fetchBody, filterHash, queryParams: query_params } = getFetchBody(
    {
      ...ownProps,
      cumulation: indicatorId ? cumulation : "total",
      category_id: category_id,
      indicator_id: indicatorId,
      filters: Im.Map({})
    },
    _key
  );
  let query_string = new URLSearchParams(query_params).toString();

  let projectsByReportingPeriods = selectedReportingPeriods?.map(rp =>
    rp.getInPath(`${_key}.${cumulation || "total"}.projects`)
  );

  let projectIds = projectsByReportingPeriods
    ?.filter(rp => rp)
    ?.map(rp => [...rp.keys()])
    .reduce((ids, id) => ids.merge(id), Im.List())
    .toSet();

  let projects = projectIds?.map(projectId => state.getInPath(`entities.projects.byId.${projectId}`)).toList();

  return {
    projects,
    projectsByReportingPeriods,
    fetchBody,
    filterHash,
    query_params,
    query_string
  };
};

export default connect(mapStateToProps)(CompanyReportProjectResults);
