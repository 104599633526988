import { iconKeySwaps } from "../utils";

export const chartBackgroundColors = [
  "#AE8CA3",
  "#90A9B7",
  "#FF5964",
  "#A3A380",
  "#3B429F",
  "#F49F0A",
  "#FFBCB5",
  "#0A210F",
  "rgba(102, 153, 255, 0.5)",
  "rgba(255, 26, 102, 0.5)",
  "#664E4C",
  "#A8577E",
  "#BB8588",
  "#F4A5AE",
  "#AA7DCE",
  "#D8A48F"
];

export const getColor = colorName => {
  if (colorName === "#cccccc" || colorName === "grey") {
    return "#a6a6a6";
  }
  if (!colorName) {
    return "#a6a6a6";
  }
  if (colorName.startsWith("#")) {
    return colorName;
  }
  //
  switch (colorName) {
    case "red":
      return "#db1919";
    case "green":
      return "#36aa47";
    case "amber":
      return "#ffcc00";
    case "grey":
      return "#c2c2c2";
  }
  //
  throw `colorName not supported. It has to be: red, green, amber or grey. Given value was: ${colorName}`;
};

export const getBgGradient = colorName => {
  switch (colorName) {
    //grey
    case "#cccccc":
      return "linear-gradient(#a6a6a6, #808080)";
    //red
    case "#db1919":
      return "linear-gradient(#FF5656, #c20000)";
    //green
    case "#36aa47":
      return "linear-gradient(#2ECC45, #007511)";
    //amber
    case "#ffcc00":
      return "linear-gradient(#ff8f00, #db7a00)";
    default:
      return "";
  }
};

export const availableIcons = new Set([
  "air-quality",
  "awards",
  "biodiversity",
  "carbon",
  "carbon-and-energy",
  "carbon-by-scope",
  "ceequal",
  "climate-change",
  "community",
  "community-wellbeing",
  "diversity-and-equality",
  "economic-prosperity",
  "edi",
  "employee-engagement",
  "employee-relations",
  "employees",
  "ems",
  "equality-diversity-inclusion",
  "ethics",
  "future-of-work",
  "governance-and-safety",
  "governance",
  "people-wellbeing",
  "health-safety",
  "local",
  "materials",
  "noise-and-vibration",
  "other",
  "process-and-productivity",
  "quality",
  "skills-and-employment",
  "social-value",
  "supply-chain",
  "waste",
  "water",
  "company-policies",
  "gender",
  "workforce",
  "survey-statistics",
  "company-policies",
  "recruitment",
  "gender",
  "ethnicity",
  "age",
  "disability",
  "religion-and-belief",
  "sexual-orientation",
  "pay-gap"
]);

export const getLogo = (logoName, iconPath) => {
  logoName = logoName.toLowerCase();
  logoName = iconKeySwaps[logoName] || logoName;
  logoName = logoName.replaceAll(/ /g, "-");

  if (!availableIcons.has(logoName)) {
    logoName = "other";
  }

  let basePath = iconPath || "/assets/images/icons/";
  let parts = basePath.split("/").filter(i => i);
  let extension = parts.pop();
  extension = extension.length === 3 ? extension : "png";
  return `${basePath}metric-category-${logoName}.${extension}`;
};

const metricNameMap = {
  ems: "Environmental Management",
  "health-safety": "Health & Safety",
  "process-and-productivity": "Process & Productivity",
  local: "Procurement",
  workforce: "Employees",
  "air-quality": "Air Quality",
  "social-value": "Social Value",
  "climate-change": "Climate Change"
};

const invMetricNameMap = () => {
  return Object.entries(metricNameMap).reduce((acc, [key, value]) => {
    acc[`${value}`] = key;
    return acc;
  }, {});
};

export const metricName = name => {
  return metricNameMap[name] || name.charAt(0).toUpperCase() + name.slice(1);
};

export const reverseMetricName = name => {
  return invMetricNameMap()[name] || name.toLowerCase();
};

export const updateHighlight = function(e, action) {
  e.preventDefault();
  this.setState({ highlight: action });
};
