import React, { useState, useEffect } from "react";

import { ErrorBoundary } from "storybook-dashboard/errors";
import IssuesRAGPanel from "storybook-dashboard/dashboard/ragPanels/issues";
import IndicatorsRAGPanel, { orderIndicators } from "storybook-dashboard/dashboard/ragPanels/indicators";
import IndicatorsBarChart from "storybook-dashboard/dashboard/charts/verticalBar";
import InputValuesTable from "storybook-dashboard/dashboard/project/inputValues";
import IndicatorPerformanceTable from "storybook-dashboard/dashboard/performanceTable";
import DataExplorer from "storybook-dashboard/dashboard/components/dataExplorer";
import DashboardFilters from "storybook-dashboard/dashboard/reportFilters"
import { Hider } from "storybook-dashboard/utils";
import Im from "immutable";
import Moment from "moment"


const INDUSTRY_DASHBOARD_ID = "9d7c6f40"
const DEFAULT_PERIOD = "year"


export const lastYearFilters = () => {
  let key = "commit__reporting_period__endDate"
  return Im.fromJS({
    [`${key}__gt`]: new Moment().utc().startOf("year").toISOString(),
    [`${key}__lte`]: new Moment().utc().add(1, "years").startOf("year").toISOString()
  })
}


export default function DashboardComponents({ companyId, iconPath, indicatorOrder, downloadExcel }) {
  let [selectedIssue, setSelectedIssue] = useState(null);
  let [selectedIndicatorIds, setSelectedIndicatorIds] = useState(Im.Map());
  let [filters, setFilters] = useState(Im.Map())

  let thisYearFilters = filters.merge(lastYearFilters())

  // Reset the selected indicators to none if the issue selection changes
  useEffect(() => {
    setSelectedIndicatorIds(Im.Map());
  }, [selectedIssue]);

  // Get a list of only the selected indicators
  let selectedIndicators = orderIndicators(selectedIndicatorIds.toList(), indicatorOrder)

  return (
    <ErrorBoundary>

      <div className="row">
        <ErrorBoundary>
          <DashboardFilters
            companyId={companyId}
            onApply={values => setFilters(values)}
            appliedFilters={filters}
          />
        </ErrorBoundary>
      </div>

      <ErrorBoundary>
        <IssuesRAGPanel
          companyId={companyId}
          iconPath={iconPath}
          selected={selectedIssue}
          setSelected={setSelectedIssue}
          filters={thisYearFilters}
          noRagColor={true}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <IndicatorsRAGPanel
          companyId={companyId}
          iconPath={iconPath}
          issue={selectedIssue}
          indicatorOrder={indicatorOrder}
          selected={selectedIndicatorIds}
          setSelected={setSelectedIndicatorIds}
          filters={thisYearFilters}
          noRagColor={true}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <IndicatorsBarChart 
          companyId={companyId} 
          indicators={selectedIndicators} 
          downloadExcel={downloadExcel} 
          filters={filters}
          period={DEFAULT_PERIOD}
          targetLabels={{target: "ONS UK population", threshold: "Industry Benchmark"}}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <Hider hide={companyId?.startsWith(INDUSTRY_DASHBOARD_ID)}>
          <DataExplorer
            companyId={companyId}
            indicators={selectedIndicators}
            filters={filters}
            options={{
              metric_values: {
                text: "Performance breakdown table",
                component: IndicatorPerformanceTable,
              },
              performance: {
                text: "Input values table",
                component: InputValuesTable,
              },
            }}
          />
        </Hider>
      </ErrorBoundary>
    </ErrorBoundary>
  );
}
