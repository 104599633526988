import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Traec from "traec";
import { connect } from "react-redux";

function UserReport({ cref }) {
  if (!cref) {
    return null;
  }

  let projectId = cref.get("project")?.substring(0, 8);
  let refId = cref.get("uid")?.substring(0, 8);
  let linkUrl = `/project/${projectId}/wpack/${refId}/report`;

  return (
    <p>
      <b>
        <Link to={linkUrl}>{cref.get("name")}</Link>
      </b>
    </p>
  );
}

function UserReports({ userRefs }) {
  //console.log("Rendering user refs", userRefs?.toJS());

  let [state, setState] = useState({});

  // Called on mount and update
  useEffect(() => {
    Traec.fetchRequiredFor({
      props: {},
      state,
      setState,
      requiredFetches: [new Traec.Fetch("tracker_ref_all", "list")]
    });
  });

  if (!userRefs?.size) {
    return null;
  }

  return userRefs.map((cref, i) => <UserReport key={i} cref={cref} />);
}

const mapStateToProps = state => {
  let allRefs = state.getInPath("entities.refs.byId") || Traec.Im.Map();

  // Map the responsible refs onto the list of projects
  let userRefs = allRefs.toList().filter(cref => cref.getInPath("latest_commit.discipline") && cref.get("depth") > 1);

  return { userRefs };
};

export default connect(mapStateToProps)(UserReports);
