import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Traec from "traec";

import { DetailedIconChart } from "AppSrc/charts";
import { BSCard } from "traec-react/utils/bootstrap";
import { generateIndicatorChart, generateIndicatorTargetMap } from "./utils";
import { CumulativeButton } from "./sustainabilityPanel/utils";
import { ErrorBoundary } from "traec-react/errors/handleError";

import { sortAndCropData, dataToState, getFetchBody, getDispatchFetches } from "./sustainabilityPanel/helpers";
import { Spinner } from "traec-react/utils/entities";
import TipsSpinner from "../utils/spinners/tipsSpinner";

const getIndicator = indicators => {
  let indicator;
  let indicatorId;
  if (indicators && indicators.size == 1) {
    indicator = indicators.first();
    indicatorId = indicator.get("_key");
    indicator = indicator.set("uid", indicatorId);
  }
  return { indicator, indicatorId };
};

export const getPreDispatchHook = props => {
  return action => {
    action.fetchParams.body = props.fetchBody;
    action.fetchParams.headers = { "content-type": "application/json" };
    action.fetchParams.rawBody = false;
    action.fetchParams.throttleTimeCheck = 1000 * 3600; // Throttle request to every hour (to prevent calling backend every click)
    action.stateParams.stateSetFunc = (state, data) => {
      console.log("Got INDICATOR_DETAIL for vertical bar chart", data);
      return dataToState(props, state, data);
    };
    console.log("Calling dispatch for INDICATOR_DETAIL data", action);
    return action;
  };
};

const IndicatorVerticalBar = props => {
  let [indicatorChartData, setIndicatorChartData] = useState(null);
  let [state, setState] = useState({ fetchedUrls: {} });

  let {
    selected,
    indicator,
    data,
    fromDate,
    toDate,
    targets,
    category,
    query_params,
    query_string,
    //cumulation,
    setCumulation,
    projectId,
    refId,
    companyId,
    isRootRef,
    filters
  } = props;

  let cumulation = "current";

  useEffect(() => {
    //Looks through props and ensures all required props are present (eg. companyId must be in props when requesting for it)
    Traec.fetchRequiredFor({
      props,
      state,
      setState,
      requiredFetches: getDispatchFetches(props, getPreDispatchHook(props))
    });
  }, [companyId, refId, query_string]);

  useEffect(() => {
    let indicatorNames = selected?.map(data => data.get("name")).toList();

    // Get the data
    let _data = sortAndCropData(data, fromDate, toDate, "INDICATOR_DATA");

    console.log("data HERE", _data?.toJS(), "category >", category);

    setIndicatorChartData(getChartData(_data, targets, cumulation, category, indicatorNames) || Traec.Im.Map());
  }, [query_params, selected, category, data]);

  if (!indicatorChartData || !indicatorChartData.size) {
    return <TipsSpinner message="Loading indicator graphs" />;
  }

  let indicatorId = indicator?.get("_key");
  let indicatorName = indicator?.get("name");

  let title = indicatorName || `All Indicators for ${category}`;
  //title = title + ` (${cumulation == "current" ? "per period" : "cumulative"})`;

  console.log("Rendering IndicatorVerticalChart with data", indicatorChartData?.toJS());

  return (
    <ErrorBoundary>
      <div className="row">
        <BSCard
          widthOffset="col-sm-12"
          title={`Annual Data`}
          // button={<CumulativeButton cumulation={cumulation} setCumulation={setCumulation} />}
          body={
            <React.Fragment>
              <DetailedIconChart data={indicatorChartData} />
              {/* <Benchmarks
                    hide={true} //(cumulation == "current")
                    target={targets?.find(target => target.getInPath("metric.uid") === indicator?.get("baseMetric"))}
                    indicator={indicator}
                /> */}
            </React.Fragment>
          }
        />
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state, ownProps) => {
  let { companyId, projectId, hostId, selected, refId } = ownProps;

  hostId = hostId || companyId || projectId;

  // Get the selected category
  let category_id = selected?.first()?.get("category_id");
  let category = selected?.first()?.get("category");

  let commitId = state.getInPath(`entities.refs.byId.${refId}.latest_commit.uid`);
  let indicators = null;
  if (hostId === companyId) {
    indicators = state.getInPath(`entities.companyObjects.byId.${hostId}.indicators`);
  } else {
    indicators = state.getInPath(`entities.commitEdges.byId.${commitId}.indicators`);
  }

  // Get the indicator details
  let indicator = selected?.size === 1 ? selected.first() : null;
  indicator = indicator ? indicator.set("uid", indicator.get("_key")) : null;
  indicator =
    indicators && indicator
      ? indicator.set("baseMetric", indicators.getInPath(`${indicator.get("uid")}.resultBaseMetric.uid`))
      : indicator;

  // Add the body of our API data call to the props (so we can get it in the requiredFetches above)
  let { fetchBody, filterHash, queryParams: query_params } = getFetchBody(
    { ...ownProps, cumulation: "current" },
    "INDICATOR_DATA"
  );
  let query_string = new URLSearchParams(query_params).toString();

  return { hostId, category, indicator, category_id, fetchBody, query_params, query_string, filterHash };
};

export default connect(mapStateToProps)(IndicatorVerticalBar);

export const getChartData = (data, targets, cumulation, category, indicatorNames) => {
  if (!data) {
    return null;
  }

  let indicatorTargetMap = generateIndicatorTargetMap(data, targets, cumulation, "INDICATOR_DATA");
  let indicatorChartData = generateIndicatorChart(indicatorTargetMap, category, indicatorNames);

  console.log("indicatorChartData", indicatorChartData?.toJS());
  return indicatorChartData.has("labels") ? indicatorChartData : null;
};
