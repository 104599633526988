import React, { useState } from "react";
import Traec from "traec";
import { Link } from "react-router-dom";

import { CompanyPermission } from "traec/utils/permissions/company";
import { BSBtnDropdown, BSModal } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";
import { ErrorBoundary } from "traec-react/errors";

import { MiniErrorBoundary } from "AppSrc/project/report/error";
import { companyFields } from "AppSrc/company/children";
import CompanyProjectForm from "AppSrc/company/projectForm";
import ProjectRow from "./projectRow";
import { Indent } from "./indent";
import { confirmDelete } from "traec-react/utils/sweetalert";

import { setAndShowModal } from "AppSrc/utils/modal";

const deleteCompany = (e, companyId, company) => {
  e.preventDefault();

  let redirectUrl = "/accounts/profile";
  if (company) {
    let parentId = (company.get("parentid") || "").substring(0, 8);
    redirectUrl = parentId ? `/company/${parentId}` : redirectUrl;
  }
  console.log("WILL REDIRECT TO", redirectUrl);

  confirmDelete({
    text: `This will delete Company (${companyId.substring(
      0,
      8
    )}).  ALL MEMBERSHIP DATA, NESTED DATA, NESTED PROJECT DATA AND HISTORICAL REPORTED DATA WILL BE PERMANENTLY LOST. Are you sure you would like to proceed?`,
    onConfirm: () => {
      confirmDelete({
        text: `Are you really really sure?  There no recovering this data.`,
        onConfirm: () => {
          confirmDelete({
            text: `...Really?`,
            onConfirm: () => {
              let fetch = new Traec.Fetch("company", "delete", { companyId });
              fetch.updateFetchParams({
                postSuccessHook: () => {
                  location.href = redirectUrl;
                }
              });
              fetch.dispatch();
            }
          });
        }
      });
    }
  });
};

function CompanyProjectList(props) {
  let { company, depth, hideProjects } = props;
  if (!company || hideProjects) {
    return null;
  }
  let projectList = company.get("projects") || Traec.Im.List();
  return projectList
    .filter(project => project)
    .sortBy(project => project.get("name"))
    .map((project, i) => <ProjectRow key={i} {...props} project={project} depth={depth + 1} />);
}

function SubCompanyList(props) {
  let { company, companyList, depth, currentIds, hideProjects } = props;
  let childIds = company.get("childids");
  if (!childIds) {
    return null;
  }

  let _children = childIds.map(id => companyList.get(id)).filter(company => company);

  return _children
    .sortBy(company => company.get("name"))
    .map((company, i) => (
      <CompanyTreeRow
        key={i}
        depth={depth + 1}
        isRoot={false}
        company={company}
        companyList={companyList}
        currentIds={currentIds}
        hideProjects={hideProjects}
      />
    ));
}

const addSubCompany = company => {
  let modalId = "CommonCompanyModal001";

  // Get the fetch for adding a business unit
  let fetch = new Traec.Fetch("company", "post");
  let styles = (company.getInPath("meta_json.styles") || Traec.Im.Map()).toJS();
  fetch.updateFetchParams({
    preFetchHook: body => ({
      ...body,
      parentid: company.get("uid"),
      meta_json: {
        styles
      }
    }),
    postSuccessHook: () => {
      $(`#${modalId}`).modal("hide");
    }
  });

  setAndShowModal(modalId, {
    title: "Add a Business Unit",
    body: <BaseFormConnected params={fetch.params} fields={companyFields} forceShowForm={true} hideUnderline={true} />
  });
};

function CompanyAdminDropdown({ companyId, company, hideProjects, hideProjectHandler }) {
  if (!company || !companyId) {
    return null;
  }

  let modalId = "CommonCompanyModal001";
  let _companyId = companyId.substring(0, 8);

  return (
    <MiniErrorBoundary>
      <BSBtnDropdown
        header={" "}
        floatStyle={"sidebar-dropdown-text float-right"}
        links={[
          {
            name: "Add a Sub-Business Unit",
            onClick: e => addSubCompany(company)
          },
          {
            name: "Add a Sub-Project",
            onClick: e => {
              setAndShowModal("CommonCompanyModal001", {
                title: "Add a Business Unit",
                body: <CompanyProjectForm companyId={companyId} company={company} modalId={modalId} />
              });
            }
          },
          { name: "Go to Settings", linkTo: `/company/${_companyId}/details` },
          {},
          {
            name: `${hideProjects ? "Show" : "Hide"} Projects`,
            onClick: hideProjectHandler
          },
          {},
          { name: "Delete", onClick: e => deleteCompany(e, companyId, company) }
        ]}
      />
    </MiniErrorBoundary>
  );
}

export default class CompanyTreeRow extends React.Component {
  constructor(props) {
    super(props);

    let { company, hideProjects } = props;
    let companyId = company ? company.get("uid") : null;

    this.state = {
      collapsed: companyId ? localStorage.getItem(`sidebar-${companyId}`, "false") == "true" : false,
      hideProjects: hideProjects,
      formParams: {},
      formFields: companyFields
    };
  }

  toggleCollapsed(e, companyId) {
    e.preventDefault();
    let value = !this.state.collapsed;
    localStorage.setItem(`sidebar-${companyId}`, value);
    this.setState({ collapsed: value });
    console.log("Set collapsed state for company", companyId, value);
  }

  render() {
    let { company, currentIds, isRoot, depth } = this.props;
    let { hideProjects } = this.state;
    if (!company) {
      return null;
    }
    let currentId = currentIds.refId || currentIds.projectId || currentIds.companyId;

    let companyId = company.get("uid");
    let isCurrent = companyId === currentId;
    let bgColor = isCurrent ? "bg-info" : "";

    let _companyId = companyId ? companyId.substring(0, 8) : null;

    let TitleTag = isRoot ? "b" : "span";

    let hasChildren =
      (company.get("childids") || Traec.Im.List()).size > 0 || (company.get("projects") || Traec.Im.List()).size > 0;

    //console.log(company.get("name"), depth)
    return (
      <ErrorBoundary>
        <div className={`row m-0 p-0 ${bgColor}`}>
          <Indent
            depth={depth}
            expanded={!this.state.collapsed && hasChildren}
            onClickHandler={e => {
              this.toggleCollapsed(e, companyId);
            }}
          />

          <p className={`m-0 p-0 mr-2 col`} style={{ display: "inline-block", verticalAlign: "middle" }}>
            <Link to={`/company/${_companyId}`}>
              <TitleTag>{company.get("name")}</TitleTag>
            </Link>
          </p>
          {this.props.extraContent}

          <MiniErrorBoundary>
            {isCurrent ? (
              <CompanyPermission companyId={currentId} requiresAdmin={true}>
                <CompanyAdminDropdown
                  companyId={companyId}
                  company={company}
                  hideProjects={hideProjects}
                  hideProjectHandler={() => this.setState({ hideProjects: !hideProjects })}
                />
              </CompanyPermission>
            ) : null}
          </MiniErrorBoundary>
        </div>

        {/* Sub-Project/Company list */}
        {!this.state.collapsed ? (
          <React.Fragment>
            <CompanyProjectList {...this.props} hideProjects={hideProjects} />
            <SubCompanyList {...this.props} hideProjects={hideProjects} />
          </React.Fragment>
        ) : null}
      </ErrorBoundary>
    );
  }
}
